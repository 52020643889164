import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import logo from './logo.svg';
import './style.css';

import { IsMobile } from '../../utils/mobile';

export default function Home() {
  const navigate = useNavigate();

  useEffect(() => {
    if (IsMobile()) {
      // Do not redirect if we're trying to use a signin route
      if (!window.location.href.includes("/mobile/login")) {
        navigate("/download")
      }
    }
  }, [navigate]);

  return (
    <div className="Home-container">
      <div className="Home-main-container">
        <main>
          <div className="Home-logo">
            <img src={logo} className="App-logo" alt="logo" />
          </div>
        </main>

        <footer className='Home-footer'>
          <a href="/terms">Terms</a>
          <a href="/privacy">Privacy</a>
          <a href="mailto:yo@tenten.app">Contact</a>
        </footer>
      </div>
    </div>
  );
}
