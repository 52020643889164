import React from 'react';
import ReactDOM from 'react-dom/client';
import Home from './components/Home';
import './index.css';
import reportWebVitals from './reportWebVitals';

import { getMobileOperatingSystem } from './utils/mobile';

import {
  Route,
  BrowserRouter as Router,
  Routes
} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path='/download' element={<RedirectToAppStore />} />
        <Route path='/terms' element={<RedirectToTerms />} />
        <Route path='/privacy' element={<RedirectToPrivacy />} />
        <Route path='/apple-app-site-association' element={<RedirectToAppleAssociation />} />

        <Route path="*" element={<Home />} />
      </Routes>
    </Router>
  </React.StrictMode>
);

function RedirectToAppStore() {
  if (getMobileOperatingSystem() === 'Android') {
    window.location.href = 'https://play.google.com/store/apps/details?id=com.tenten.app';
  } else {
    window.location.href = 'https://apps.apple.com/us/app/ten-ten-walkie-talkie/id1481768339';
  }

  return null;
}

function RedirectToTerms() {
  window.location.href = 'https://tentenapp.notion.site/Terms-Conditions-3db4b5652a4146d3b9a97b568fbc360f';
  return null;
}

function RedirectToPrivacy() {
  window.location.href = 'https://tentenapp.notion.site/Privacy-8ca58e561a534380838e5ff6e861a268';
  return null;
}

function RedirectToAppleAssociation() {
  window.location.pathname = ".well-known/apple-app-site-association"
  return null;
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
